import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["runways", "runwayTemplate"]

    add_runway(){
        // @ts-ignore
        const current_runway_count = parseInt(this.runwaysTarget.dataset.runways);

        // @ts-ignore
        const html = this.runwayTemplateTarget.content.cloneNode(true);
        html.querySelectorAll('[id="runway_$$"]')[0].setAttribute('id', `runway_${current_runway_count}`)
        html.querySelectorAll('button')[0].setAttribute('data-runway', `${current_runway_count}`)
        const runway_input_div = html.querySelectorAll('[id="runway___"]')[0]
        runway_input_div.setAttribute('id', `runway_${current_runway_count}`)
        runway_input_div.setAttribute('name', `runway_${current_runway_count}`)
        const runway_label_div = html.querySelectorAll('[for="runway___"]')[0]
        runway_label_div.setAttribute('for', `runway_${current_runway_count}`)

        const runway_heading_input_div = html.querySelectorAll('[id="runway_number___"]')[0]
        runway_heading_input_div.setAttribute('id', `runway_number_${current_runway_count}`)
        runway_heading_input_div.setAttribute('name', `runway_number_${current_runway_count}`)
        const runway_heading_label_div = html.querySelectorAll('[for="runway_number___"]')[0]
        runway_heading_label_div.setAttribute('for', `runway_number_${current_runway_count}`)

        // @ts-ignore
        this.runwaysTarget.append(html);
        // @ts-ignore
        this.runwaysTarget.dataset.runways = current_runway_count + 1;
    }

    remove_runway(event){
        let elem = event.target;
        if(elem.tagName == 'I'){
            elem = elem.parentElement;
        }
        const runway = elem.dataset.runway;
        document.getElementById(`runway_${runway}`).remove();
    }
}