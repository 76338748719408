import {Controller} from "@hotwired/stimulus"
export default class extends Controller{

    scroll(event: PointerEvent){
        // @ts-ignore
        const target_id = event.target.dataset.target;
        let e = document.getElementById(target_id);
        e.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start'
        });
    }

}