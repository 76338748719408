import {Controller} from "@hotwired/stimulus"
import {callable_functions} from "./support/callable";

export default class extends Controller{
    static targets = ["select", "dropdown", "collapsible", "tooltip"]

    connect() {
        // @ts-ignore
        if(this.hasSelectTarget){
            this.select();
        }

        // @ts-ignore
        if(this.hasDropdownTarget){
            this.dropdown();
        }

        // @ts-ignore
        if(this.hasCollapsibleTarget){
            this.collapsible();
        }

        // @ts-ignore
        if(this.hasTooltipTarget){
            this.tooltip();
        }

        M.updateTextFields();
    }

    tooltip(){
        // @ts-ignore
        this.tooltipTargets.forEach(function(elem){
            // @ts-ignore
            M.Tooltip.init(elem, {});
        })
    }

    collapsible(){
        // @ts-ignore
        this.collapsibleTargets.forEach(function(elem){
            // @ts-ignore
            M.Collapsible.init(elem, {});
        })
    }

    dropdown(){
        // @ts-ignore
        this.dropdownTargets.forEach(function(elem){
            // @ts-ignore
            M.Dropdown.init(elem, {
                'coverTrigger': false,
                'constrainWidth': false
            });
        })
    }

    select(){
        // @ts-ignore
        this.selectTargets.forEach(function(elem){
            const onChange = elem.dataset.onChange;
            // @ts-ignore
            M.FormSelect.init(elem, {});
            if (onChange !== undefined){
                elem.onchange = function(){
                    callable_functions[onChange](this);
                }
                callable_functions[onChange](this);
            }
        });
    }
}