import {Controller} from "@hotwired/stimulus"
declare var google: any;
export default class extends Controller{
    static targets = ["map"]

    connect(){
        if (typeof (google) != "undefined"){
            this.initializeMap()
        }

    }

    initializeMap() {

        // @ts-ignore
        const elem = this.mapTarget;
        const self = this;

        if(google.maps === undefined || elem === undefined){
            setTimeout(function(){
                self.initializeMap();
            }, 500);
            return;
        }


        const lat = parseFloat(elem.dataset.lat);
        const lon = parseFloat(elem.dataset.lon);
        const pos = {lat: lat, lng: lon}



        let map = new google.maps.Map(elem, {
            center: pos,
            zoom: 14,
            mapTypeId: 'hybrid',
            streetViewControl: false
        })
        let airport = new google.maps.Marker({
            position: pos,
            map: map
        })
    }




}