import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["sidenav", "background"]

    open(){
        // @ts-ignore
        const sidenav = this.sidenavTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        sidenav.style.transform = 'translateX(0%)';
        background.style.display = 'block'
        background.style.opacity = '1';

    }

    close(){
        // @ts-ignore
        const sidenav = this.sidenavTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        sidenav.style.transform = '';
        background.style.display = ''
        background.style.opacity = '';
    }

}