// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import "controllers"
import {Turbo} from "@hotwired/turbo-rails";
import '@materializecss/materialize/dist/js/materialize'
import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from '@sentry/browser'
require("@rails/activestorage").start();
require("../channels");

// @ts-ignore
const images = require.context("../images", true);
export const imagePath = (name) => images(name, true);

// @ts-ignore
window.dispatchMapsEvent = function (...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    // @ts-ignore
    event.args = args
    window.dispatchEvent(event)
}

window.addEventListener("trix-file-accept", function(event) {
    event.preventDefault()
    alert("File attachment not supported!")
})

Turbo.start();
require("trix")
require("@rails/actiontext")

const sentry_run = '1';
const sentry_dsn = 'https://abbb093001714c3ea6a77964bb6f946d@o1154850.ingest.sentry.io/6469518'
let environment = 'master'
if(environment == 'master'){
    environment = 'production'
}
if(environment != 'production' && environment != 'development' ){
    environment = `review-${environment}`;
}
const release = 'a48b908b'
const trace_rate = environment == 'development' ? 0.1 : 0.5

if(sentry_run == '1'){
    Sentry.init({
        dsn: sentry_dsn,
        release: release,
        environment: environment,

        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        integrations: [
            new BrowserTracing(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampler: samplingContext => {
            if (samplingContext.parentSampled !== undefined) {
                return samplingContext.parentSampled
            }
            if (samplingContext.transactionContext.name == '/health') {
                // Drop this transaction, by setting its sample rate to 0%
                return 0;
            } else {
                // Default sample rate for all others (replaces tracesSampleRate)
                return trace_rate;
            }
        }
    });
}