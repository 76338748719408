import {Controller} from "@hotwired/stimulus"
export default class extends Controller{
    static targets = ["content"]
    collapsed: boolean = true;

    toggle(){
        if(this.collapsed){
            this.collapsed = false
            // @ts-ignore
            this.contentTarget.classList.remove('max-h-0')
            // @ts-ignore
            this.contentTarget.classList.add('max-h-[1500px]')
        }
        else {
            this.collapsed = true
            // @ts-ignore
            this.contentTarget.classList.add('max-h-0')
            // @ts-ignore
            this.contentTarget.classList.remove('max-h-[1500px]')
        }
    }


}