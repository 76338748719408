import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["modal", "background"]

    open(){
        // @ts-ignore
        const modal = this.modalTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        modal.style.display = 'block';
        background.style.display = 'block'
        background.style.opacity = '1';

    }

    close(){
        // @ts-ignore
        const modal = this.modalTarget;
        // @ts-ignore
        const background = this.backgroundTarget;

        modal.style.display = '';
        background.style.display = ''
        background.style.opacity = '';
    }

}