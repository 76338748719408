import {Controller} from "@hotwired/stimulus"
import {Turbo} from "@hotwired/turbo-rails";
export default class extends Controller{
    static targets = ["submit"]

    submit(){
        // @ts-ignore
        this.submitTarget.click();
    }

    visit(event: PointerEvent){
        let target: HTMLElement = event.target as HTMLElement;
        if(target.nodeName == 'TD'){
            target = target.parentElement;
        }
        let url = target.dataset.href;

        Turbo.visit(url);
    }

}