import {Controller} from "@hotwired/stimulus"

export default class extends Controller{
    static targets = ["flash"]

    connect() {
        // @ts-ignore
        if(this.hasFlashTarget){
            this.flash();
        }
    }

    flash(){
        const self = this;
        // @ts-ignore
        this.flashTargets.forEach(function(elem){
            let duration = elem.dataset.duration;
            if(duration === undefined){
                duration = 5000;
            } else {
                duration = parseInt(duration)
            }
            elem.classList.remove('hidden')
            setTimeout( function(){
                self.remove_flash(elem)
            }, duration)
        })
    }

    remove_flash(elem){
        elem.remove();
    }
}